import { render, staticRenderFns } from "./PasswordRecovery.vue?vue&type=template&id=968fadb6&scoped=true&"
import script from "./PasswordRecovery.vue?vue&type=script&lang=ts&"
export * from "./PasswordRecovery.vue?vue&type=script&lang=ts&"
import style0 from "./PasswordRecovery.vue?vue&type=style&index=0&id=968fadb6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "968fadb6",
  null
  
)

export default component.exports