
import { Component, Vue } from "vue-property-decorator";
import { email, required, checkEmailInDB } from "@/helpers/rules";
import { authenticate } from "@/services/api/authenticate.service";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class PasswordRecovery extends Vue {
  private valid = true;
  private loading = false;
  private email = email;
  private required = required;
  private checkEmailInDB = checkEmailInDB;

  private formEmail = "";
  private emailNotUsed = false;

  async onSubmit(): Promise<void> {
    const isValid = (
      this.$refs.form as Vue & { validate: () => boolean }
    ).validate();
    this.valid = isValid;
    if (isValid) {
      overlayModule.showOverlay();
      await authenticate.requestPasswordReset(this.formEmail);
      snackbarModule.showSnackbar({
        message: "Email per il reset della password inviata con successo",
        type: "success",
      });
      setTimeout(() => snackbarModule.hideSnackbar(), 2000);
      this.$router.push({ name: "login" });
    }
  }

  private async checkEmailDispatch(): Promise<void> {
    if (this.email(this.formEmail) == true) {
      this.loading = true;
      const r = await authenticate.checkEmail(this.formEmail);
      this.loading = false;
      this.emailNotUsed = r.data;
    }
  }
}
